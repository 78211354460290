<template>
  <div class="topic-introduction-page">
    <v-card
      v-if="introduction && introduction.is_draft"
      color="yellow lighten-4"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-wrap body-2"
            >This topic introduction is marked as
            <strong>'Draft'</strong></v-list-item-title
          >
        </v-list-item-content>

        <v-list-item-icon v-if="canManage"
          ><v-btn @click="showPublishDialog = true" small color="primary"
            ><v-icon class="mr-2">mdi-publish</v-icon> Publish</v-btn
          ></v-list-item-icon
        >
      </v-list-item>
    </v-card>
    <v-row justify="center">
      <v-col md="6">
        <div class="mx-4">
          <!-- intro description -->
          <div class="my-2 mx-2" v-if="introduction">
            <p
              class="content-description"
              style="word-break: break-word"
              v-html="
                TextHelper.linkify(
                  Helper.markuptext(introduction.description, '*', 'strong')
                )
              "
            ></p>
          </div>
          <!-- intro images -->
          <v-row
            class="my-2 mx-1"
            dense
            v-if="introduction && introduction.topic_intro_images.length"
          >
            <v-col
              cols="4"
              md="3"
              v-for="image in introduction.topic_intro_images"
              :key="image.id"
            >
              <v-card outlined elevation="0">
                <v-img
                  class="cursor-pointer"
                  @click="showFullScreenCarousel(image)"
                  :src="image.image"
                  aspect-ratio="1"
                >
                </v-img>
              </v-card>
            </v-col>
          </v-row>
          <!-- intro videos -->
          <v-row class="my-2 mx-1" dense v-if="introduction">
            <v-col
              cols="4"
              md="3"
              v-for="video in introduction.topic_intro_videos"
              :key="video.id"
            >
              <v-card outlined elevation="0">
                <v-img
                  width="200px"
                  class="cursor-pointer"
                  :src="Helper.getYouTubeThumbnailURL(video.link)"
                  aspect-ratio="1.5"
                >
                  <!-- <div class="image-icon"> -->
                  <v-btn
                    class="image-icon"
                    icon
                    @click="showYouTubeVideo(video)"
                    ><v-icon size="40px" color="white">mdi-play</v-icon></v-btn
                  >
                  <!-- </div> -->
                </v-img>
              </v-card>
            </v-col>
          </v-row>

          <v-divider
            v-if="introduction && introduction.topic_intro_documents.length"
          />
          <!-- intro documents -->
          <v-list
            class="my-0 py-0"
            v-if="introduction && introduction.topic_intro_documents.length"
          >
            <v-list-item
              class="px-0"
              color="red"
              v-for="introDocument in introduction.topic_intro_documents"
              :key="introDocument.id"
            >
              <v-list-item-avatar>
                <v-icon>mdi-file</v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="py-0">
                <v-list-item-subtitle
                  class="font-weight-bold"
                  v-if="introDocument.file_name.split('.').length > 1"
                >
                  {{
                    introDocument.file_name
                      .split(".")
                      .slice(-1)[0]
                      .toUpperCase()
                  }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="subtitle-2 text-wrap">
                  {{ introDocument.file_name }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon class="align-center">
                <v-btn
                  outlined
                  color="primary"
                  class="non-focused"
                  v-if="introDocument.progress == 100"
                  @click="openFile(introDocument)"
                  ><v-icon>mdi-check</v-icon> Open</v-btn
                >
                <v-btn
                  @click="downloadDocument(introDocument)"
                  color="primary"
                  class="non-focused"
                  icon
                  v-else
                >
                  <v-icon v-if="introDocument.progress == null"
                    >mdi-download</v-icon
                  >
                  <span v-else>{{ introDocument.progress }}%</span>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </div>
      </v-col>
    </v-row>

    <!-- image carousel -->
    <full-screen-image-carousel
      v-if="introduction"
      :initial-image="initialImage"
      :images="introduction.topic_intro_images"
      :visible="fullScreenImageOverlay"
      @closeDialog="fullScreenImageOverlay = false"
    />
    <!-- publish dialog -->
    <confirmation-dialog
      title="Are you sure you want to publish this Introuduction?"
      description="This will notify all the students from this classroom that the topic introduction has been published."
      :visible="showPublishDialog"
      @successCallback="publishIntro"
      @failureCallback="showPublishDialog = false"
    />
    <!-- delete dialog -->
    <confirmation-dialog
      :visible="showDeleteDialog"
      title="Are you sure you want to delete this introduction?"
      description="This action cannot be undone"
      @successCallback="deleteIntroduction"
      @failureCallback="showDeleteDialog = false"
      delete-dialog
    />
    <!-- create edit sheet -->
    <intract-create-edit-sheet
      persistent
      class="introduction-create-edit-sheet"
      :title="
        introSheet.editId
          ? 'Edit Topic Introduction'
          : 'Create Topic Introduction'
      "
      description="Introduce your students to a new topic"
      :visible="introSheet.visible"
      @close="
        introSheet.visible = false;
        introSheet.editId = null;
      "
      :fields="introFormFields"
      :endpoint="endpoints.topicIntroductions"
      :edit-id="introSheet.editId"
      :data-object="introSheet.obj"
      :create-success-message="introSheet.createSuccessMessage"
      :edit-success-message="introSheet.editSuccessMessage"
      enable-media
      @objectCreated="getIntroDetails()"
      @updateObject="(obj) => (introSheet.obj = obj)"
    />

    <FullScreenYTDialog
      v-if="videoLink"
      :visible="showVideo"
      :link="videoLink"
      @close="
        showVideo = false;
        videoLink = null;
      "
    />
  </div>
</template>
<script>
import TextHelper from "@utils/text_helper";
import Mixins from "@utils/mixins";
import ConfirmationDialog from "@components/dialogs/ConfirmationDialog";
import FullScreenYTDialog from "@components/dialogs/FullScreenYTDialog";
import EventBus from "@utils/event_bus";
import FullScreenImageCarousel from "@components/dialogs/FullScreenImageCarousel";
import { mapActions } from "vuex";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
export default {
  name: "TopicIntroduction",
  mixins: [
    Mixins.essentials,
    Mixins.pullToRefresh,
    Mixins.handleAppBarTitle,
    Mixins.downloadEssentials,
  ],
  components: {
    FullScreenImageCarousel,
    ConfirmationDialog,
    IntractCreateEditSheet,
    FullScreenYTDialog,
  },
  props: {
    introTitle: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      TextHelper,
      appBarTitle: this.introTitle,
      initialImage: null,
      pr: null,
      introSheet: {
        visible: false,
        createSuccessMessage: "Topic Introduction created successfully!",
        editSuccessMessage: "Topic Introduction edited successfully!",
        editId: null,
        obj: {
          book_topic: null,
          is_draft: false,
          creator: null,
        },
      },
      showPublishDialog: false,
      introduction: null,
      showDeleteDialog: false,
      fullScreenImageOverlay: false,
      showVideo: false,
      videoLink: null,
    };
  },
  computed: {
    canManage() {
      return this.introduction && this.currentUser.is_faculty;
    },
    documentsList() {
      return this.introduction.topic_intro_documents;
    },
    introFormFields() {
      return {
        title: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Title",
          required: true,
          md: 12,
          max: 255,
        },
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description",
          required: true,
          md: 12,
          max: 3000,
        },
        is_draft: {
          fieldType: CreateEditFieldTypes.CHECKBOX,
          label: "Save as draft?",
          md: 12,
          helper:
            "Enabling this will save this topic as draft and will not be shown to your students",
        },
        topic_intro_images: {
          fieldType: CreateEditFieldTypes.IMAGEUPLOAD,
          multiple: true,
          label: "Images",
          helper: "JPG, PNG, GIF, etc",
        },
        topic_intro_documents: {
          fieldType: CreateEditFieldTypes.FILEUPLOAD,
          multiple: true,
          label: "Documents",
          helper: "PDF, Video, Audio, Docs (Upto 30MB)",
        },
        topic_intro_videos: {
          fieldType: CreateEditFieldTypes.LINKUPLOAD,
          multiple: true,
          label: "Video Link",
          required: true,
        },
      };
    },
  },
  methods: {
    ...mapActions(["setAppBarOptions", "resetAppBarOptions"]),

    async showFullScreenCarousel(image) {
      this.initialImage = image;
      this.fullScreenImageOverlay = true;
    },

    async closeFullScreenCarousel() {
      this.initialImage = null;
      this.fullScreenImageOverlay = false;
    },

    async getIntroDetails() {
      var url =
        this.endpoints.topicIntroductions + this.$route.params.introId + "/";
      this.introduction = await this.api.call(this.essentials, url);
      EventBus.$emit(
        "topic_introduction__introduction_updated",
        this.introduction
      );
      this.appBarTitle = this.introduction.title;
      this.introSheet.obj.book_topic = this.introduction.book_topic;
      this.checkIfDocumentsDownloaded();
    },

    async publishIntro() {
      this.showPublishDialog = false;

      var url = this.endpoints.topicIntroductions + this.introduction.id + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.PATCH,
        { is_draft: false }
      );
      if (response) {
        this.introduction.is_draft = false;
        this.showSnackbar({
          title: "Topic Introduction has been published",
          text: "Students of this classroom will be notified!",
          type: "success",
        });
        EventBus.$emit(
          "topic_introduction__introduction_updated",
          this.introduction
        );
      }
    },

    async deleteIntroduction() {
      this.showDeleteDialog = false;
      var url =
        this.endpoints.topicIntroductions + this.$route.params.introId + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        this.showSnackbar({
          title: "Topic introduction successfully deleted",
          type: "success",
        });
        EventBus.$emit(
          "topic_introduction__introduction_deleted",
          this.$route.params.introId
        );
        this.$router.back();
      }
    },

    getYouTubeThumbnailURL(link) {
      console.log(link);
      var VID_REGEX =
        /(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      var videoId = link.match(VID_REGEX)[1];
      return `https://img.youtube.com/vi/${videoId}/0.jpg`;
    },

    async showYouTubeVideo(video) {
      this.showVideo = true;
      this.videoLink = video.link;
    },

    async setAppBar() {
      this.setAppBarOptions({
        title: this.appBarTitle,
        actions: [
          {
            id: 1,
            title: "Edit",
            icon: "mdi-pencil",
            onClick: () => {
              this.introSheet.visible = true;
              this.introSheet.editId = Number(this.$route.params.introId);
            },
          },
          {
            id: 2,
            title: "Delete",
            icon: "mdi-delete",
            onClick: () => (this.showDeleteDialog = true),
          },
        ],
      });
    },

    onRefresh() {
      this.getIntroDetails();
    },
  },

  beforeDestroy() {
    this.resetAppBarOptions();
  },

  async created() {
    await this.getIntroDetails();
    if (this.canManage) this.setAppBar();
    this.introSheet.obj.creator = this.currentUser.id;
  },
};
</script>
<style scoped>
.image-icon {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>